import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Input, Progress, Container, Alert, Table } from 'reactstrap';
import * as XLSX from 'xlsx';
import { FaWhatsapp, FaCheckCircle } from 'react-icons/fa';

const CicilanMahasiswa = () => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  // Fetch data from the API on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://rlagency.id/api/cicilan_mahasiswa/api_get_data_cicilan.php');
        if (response.data.status === 'success') {
          setData(response.data.data);
          setFilteredData(response.data.data);
        } else {
          setErrorMessage(response.data.message);
        }
      } catch (error) {
        setErrorMessage('Error fetching data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      setFile(selectedFile);
    } else {
      setErrorMessage('Please select a valid XLSX file.');
      setFile(null);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setErrorMessage('No file selected.');
      return;
    }

    setUploading(true);
    setProgress(0);
    setErrorMessage('');
    setSuccessMessage('');

    const formData = new FormData();
    formData.append('file', file);

    try {
      // Read the XLSX file
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        // Prepare data to be sent to the server
        const processedData = jsonData.map(row => ({
          nim: row.NIM,
          name: row.Name,
          phone: row.Phone,
          email: row.Email,
          kodejurusan: row.KodeJurusan,
          kelas: row.Kelas,
          status: row.status,
          status_bayar: row.status_bayar,
          dikirim: 0,  // Initial value for dikirim
          invalid: 0   // Initial value for invalid
        }));

        try {
          const response = await axios.post('https://rlagency.id/api/cicilan_mahasiswa/api_upload_data_cicilan.php', processedData, {
            headers: { 'Content-Type': 'application/json' },
            onUploadProgress: (progressEvent) => {
              const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setProgress(percent);
            }
          });

          if (response.data.status === 'success') {
            setSuccessMessage(response.data.message);
          } else {
            setErrorMessage(response.data.message);
          }
        } catch (error) {
          setErrorMessage('Error uploading the file.');
        } finally {
          setUploading(false);
        }
      };
      reader.readAsBinaryString(file);
    } catch (error) {
      setErrorMessage('Error reading the file.');
      setUploading(false);
    }
  };

  const handleUpdateStatus = async (id, column) => {
    try {
      const response = await axios.post('https://rlagency.id/api/cicilan_mahasiswa/api_update_data_cicil.php', { id, [column]: 1 });
      if (response.data.status === 'success') {
        setData(data.map(row => row.id === id ? { ...row, [column]: 1 } : row));
      }
    } catch (error) {
      setErrorMessage('Error updating status.');
    }
  };

  const handleUpdateDikirim = async (id) => {
    try {
        const response = await axios.post('https://rlagency.id/api/cicilan_mahasiswa/api_update_data_cicil.php', {
            id,
            dikirim: 1
        });

        if (response.data.status === 'success') {
            // Update the data to reflect the success of the operation
            const updatedData = data.map(row => row.id === id ? { ...row, dikirim: 1 } : row);
            setData(updatedData);
            setFilteredData(updatedData);  // Update filteredData as well
            setSuccessMessage('Data berhasil diperbarui!');
        } else {
            setErrorMessage('Gagal memperbarui data.');
        }
    } catch (error) {
        setErrorMessage('Terjadi kesalahan saat memperbarui data.');
    }
  };

  const handleUpdateInvalid = async (id) => {
    try {
      const response = await axios.post('https://rlagency.id/api/cicilan_mahasiswa/api_update_data_cicil.php', {
        id,
        invalid: 1
      });
  
      if (response.data.status === 'success') {
        // Update the data to reflect the success of the operation
        const updatedData = data.map(row => row.id === id ? { ...row, invalid: 1 } : row);
        setData(updatedData);
        setFilteredData(updatedData);  // Update filteredData as well
        setSuccessMessage('Data berhasil diperbarui!');
      } else {
        setErrorMessage('Gagal memperbarui data.');
      }
    } catch (error) {
      setErrorMessage('Terjadi kesalahan saat memperbarui data.');
    }
  };
  

  const handlePhoneLink = (phone, name) => {
    const formattedPhone = phone.startsWith('0') ? `62${phone.slice(1)}` : phone;
    const message = `Assalamu'alaikum ${name},`; // Custom message including student's name
    const encodedMessage = encodeURIComponent(message); // URL encode the message
    window.open(`https://wa.me/${formattedPhone}?text=${encodedMessage}`, '_blank');
  };
  

  const handleSortNIM = () => {
    const sortedData = [...filteredData];
    sortedData.sort((a, b) => {
      if (sortDirection === 'asc') {
        setSortDirection('desc');
        return a.NIM > b.NIM ? 1 : -1;
      } else {
        setSortDirection('asc');
        return a.NIM < b.NIM ? 1 : -1;
      }
    });
    setFilteredData(sortedData);
  };

  const handleSearch = (e, column) => {
    const searchValue = e.target.value.toLowerCase();
    const filtered = data.filter((row) =>
      row[column].toString().toLowerCase().includes(searchValue)
    );
    setFilteredData(filtered);
  };

  const handleRowSelect = (id) => {
    setSelectedRow(id === selectedRow ? null : id);
  };

  return (
  <Container style={{ marginTop: '20px' }}>
  <h4>Upload Data Mahasiswa</h4>
  <hr />
  <div className="mb-3">
    <Input type="file" accept=".xlsx" onChange={handleFileChange} />
  </div>

  {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
  {successMessage && <Alert color="success">{successMessage}</Alert>}

  <Button
    color="primary"
    onClick={handleUpload}
    disabled={uploading}
    className="mb-3"
  >
    {uploading ? 'Uploading...' : 'Upload File'}
  </Button>

  {uploading && (
    <div>
      <Progress value={progress} max={100} />
      <p>{progress}%</p>
    </div>
  )}

  <h4 style={{ paddingTop: '10px' }}>Data Cicilan Mahasiswa ({filteredData.length})</h4>
  <hr />

  {loading ? (
    <p>Loading...</p>
  ) : (
    <div style={{ maxHeight: '620px', overflowY: 'auto', borderRadius:'10px' }}>
      <Table responsive striped style={{ border: 'solid', borderWidth: '1px', color: '#f5f5f5' }}>
        <thead>
          <tr>
            <th>No</th>
            <th>
              NIM
              <Button size="sm" color="link" onClick={handleSortNIM}>
                {sortDirection === 'asc' ? '↓' : '↑'}
              </Button>
              <Input
                type="text"
                placeholder="Filter by NIM"
                onChange={(e) => handleSearch(e, 'NIM')}
                className="mb-2"
              />
            </th>
            <th>
              Name
              <Input
                type="text"
                placeholder="Filter by Name"
                onChange={(e) => handleSearch(e, 'Name')}
                className="mb-2"
              />
            </th>
            <th>
              Phone
              <Input
                type="text"
                placeholder="Filter by Phone"
                onChange={(e) => handleSearch(e, 'Phone')}
                className="mb-2"
              />
            </th>
            <th>
              Email
              <Input
                type="text"
                placeholder="Filter by Email"
                onChange={(e) => handleSearch(e, 'Email')}
                className="mb-2"
              />
            </th>
            <th>
              Kode Jurusan
              <Input
                type="text"
                placeholder="Filter by Kode Jurusan"
                onChange={(e) => handleSearch(e, 'KodeJurusan')}
                className="mb-2"
              />
            </th>
            <th>
              Kelas
              <Input
                type="text"
                placeholder="Filter by Kelas"
                onChange={(e) => handleSearch(e, 'Kelas')}
                className="mb-2"
              />
            </th>
            <th>Status Bayar</th>
            <th>Dikirim</th>
            <th>Invalid</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((row, index) => (
            <tr
              key={row.id}
              onClick={() => handleRowSelect(row.id)}
              style={{
                backgroundColor: row.id === selectedRow ? '#d3f9d8' : '',
              }}
            >
              <td>{index + 1}</td>
              <td>{row.NIM}</td>
              <td>{row.Name}</td>
              <td>
                {row.Phone}
                <FaWhatsapp
                  style={{ marginLeft: '10px', cursor: 'pointer', color: 'green' }}
                  onClick={() => handlePhoneLink(row.Phone, row.Name)}
                />
              </td>
              <td>{row.Email}</td>
              <td>{row.KodeJurusan}</td>
              <td>{row.Kelas}</td>
              <td>{row.status}</td>
              <td>
                {row.dikirim == 1 ? (
                  <FaCheckCircle color="green" />
                ) : (
                  <Button color="success" onClick={() => handleUpdateDikirim(row.id)}>
                    Done
                  </Button>
                )}
              </td>
              <td>
                {row.invalid == 1 ? (
                  <FaCheckCircle color="red" />
                ) : (
                  <Button color="danger" onClick={() => handleUpdateInvalid(row.id)}>
                    Mark Invalid
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )}
</Container>

  );
};

export default CicilanMahasiswa;
