import { useState } from 'react';
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import QRCodeGenerator from './testdrop';
import SignupForm from './formulir';
import './style.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@coreui/coreui/dist/css/coreui.min.css';

import Pendaftaran from './wisuda/pendaftaran';
import CekData from './wisuda/cekdata';
import DaftarSuperAdmin from './admin/daftarsuperadmin';
import LoginSuperAdmin from './admin/loginadmin';
import DashboardAdmin from './admin/dashboardadmin';
import DashboardAdmin1 from './admin/dashtest';
import CicilanMahasiswa from './cicilan_mhs/cicilanmahasiswa';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Pendaftaran />} />
        <Route path='/cekdata' element={<CekData />} />
        <Route path='/daftaradmin' element={<DaftarSuperAdmin />} />
        <Route path='/login' element={<LoginSuperAdmin />} />
        <Route path='/dashboard' element={<DashboardAdmin />} />
        <Route path='/dash' element={<DashboardAdmin1 />} />
        <Route path='/cicil' element={<CicilanMahasiswa />} />
      </Routes>
    </Router>
  );
}

export default App;
