import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import axios from 'axios';

const DaftarSuperAdmin = () => {
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalHeader, setModalHeader] = useState('');

    const toggleModal = () => setModal(!modal);

    const checkUsername = async (username) => {
        try {
            const response = await axios.post('https://rlagency.id/api/daftarsuperadmin.php', {
                username,
            });

            return response.data;
        } catch (error) {
            console.error('Error checking username:', error);
        }
    };

    const handleSubmit = async (values, {resetForm}) => {
        try {
            const response = await axios.post('https://rlagency.id/api/daftarsuperadmin.php', values);
            if (response.data.error) {
                setModalMessage(response.data.error);
                setModalHeader(`Error!`);
                toggleModal();
            } else {
                // Handle successful registration
                setModalMessage(`Pendaftaran ${values.username} berhasil`);
                setModalHeader(`Berhasil`);
                toggleModal();
                resetForm();
            }
        } catch (error) {
            setModalMessage('Terjadi kesalahan. Silakan coba lagi.');
            toggleModal();
        }
    };

    return (
        <div>
            <h1>Pendaftaran Super Admin</h1>
            <Formik
                initialValues={{ name: '', username: '', level: '', password: '' }}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <FormGroup>
                            <Label for="name">Nama</Label>
                            <Field name="name" as={Input} type="text" id="name" placeholder="Masukkan Nama" />
                            <ErrorMessage name="name" component="div" className="text-danger" />
                        </FormGroup>

                        <FormGroup>
                            <Label for="username">Username</Label>
                            <Field name="username" as={Input} type="text" id="username" placeholder="Masukkan Username" />
                            <ErrorMessage name="username" component="div" className="text-danger" />
                        </FormGroup>

                        <FormGroup>
                            <Label for="level">Level</Label>
                            <Field name="level" as={Input} type="select" id="level">
                                <option value="">Pilih Level</option>
                                <option value="Administrator">Administrator</option>
                                <option value="Admin">Admin</option>
                                <option value="Contributor">Contributor</option>
                            </Field>
                            <ErrorMessage name="level" component="div" className="text-danger" />
                        </FormGroup>

                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Field name="password" as={Input} type="password" id="password" placeholder="Masukkan Password" />
                            <ErrorMessage name="password" component="div" className="text-danger" />
                        </FormGroup>

                        <Button type="submit" color="primary" disabled={isSubmitting}>
                            Daftar
                        </Button>
                    </Form>
                )}
            </Formik>

            {/* Modal for messages */}
            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>{modalHeader}</ModalHeader>
                <ModalBody>
                    {modalMessage}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleModal}>Tutup</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DaftarSuperAdmin;
