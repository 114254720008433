import { Container } from "reactstrap";

import NavbarPage from "../components/NavbarPage";
import SignupForm from "../formulir";
import HeaderSection from "../components/header";
import Footer from "../components/footer";

const Pendaftaran = () => {
    return (
        <div style={{backgroundColor:'#F6F6F6'}}>
            <NavbarPage />

            <Container style={{paddingTop:'100px', marginBottom:'50px'}}>
                <HeaderSection />
                <br/>
                <SignupForm />
            </Container>
            <Footer />
        </div>


    );
};

export default Pendaftaran;