import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, CardText, Container, Row, Col, Progress } from 'reactstrap';
import axios from 'axios';

const Menu2 = () => {
  const [data, setData] = useState({
    total: 0,
    selesai: 0,
    belum_selesai: 0,
    foto_selesai:0,
    foto_pending:0,
  });

  useEffect(() => {
    // Fetch data dari API
    axios.get('https://rlagency.id/api/count_data.php')
      .then(response => {
        setData({
          total: parseInt(response.data.total),
          selesai: parseInt(response.data.selesai),
          belum_selesai: parseInt(response.data.belum_selesai),
          foto_selesai: parseInt(response.data.foto_selesai),
          foto_pending: parseInt(response.data.foto_pending),
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Menghitung persentase selesai
  const calculateProgress = () => {
    if (data.total === 0) return 0;
    return (data.selesai / data.total) * 100;
  };

  return (
    <Container fluid style={{padding:'0', margin:'0'}}>
      <Row className="justify-content-center ">
        {/* Box Selesai */}
        <Col md="2">
          <Card className="text-center" style={{backgroundColor:'#f9b115'}}>
            <CardBody>
              <CardTitle tag="h2">{data.selesai}</CardTitle>
              <CardText>Sudah Dikirim</CardText>
            </CardBody>
          </Card>
        </Col>

        {/* Box Belum Selesai */}
        <Col md="2">
          <Card className="text-center" >
            <CardBody>
              <CardTitle tag="h2">{data.foto_selesai}</CardTitle>
              <CardText>Edit Foto Selesai</CardText>
            </CardBody>
          </Card>
        </Col>

        {/* Box Jumlah Semua Data */}
        <Col md="2" >
          <Card className="text-center" >
            <CardBody>
              <CardTitle tag="h2">{data.total}</CardTitle>
              <CardText>Jumlah Pendaftar</CardText>
            </CardBody>
          </Card>
        </Col>

        {/* Box Progress */}
        <Col >
          <Card className="text-center" style={{ height: '100%', justifyContent:'space-between' }}>
            <CardBody >
              <CardTitle style={{ marginBottom:'20px'}}>Progress</CardTitle>
              <Progress value={calculateProgress()} striped animated color='green' style={{ height: '20px' }}>
                {Math.round(calculateProgress())}%
              </Progress>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Menu2;
