import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Footer.css'; // Custom CSS untuk footer styling

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col className="text-center">
            <p className="footer-copyright">
              &copy; {new Date().getFullYear()} UPI YPTK. All Rights Reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
