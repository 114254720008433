// import React, { useState } from 'react';
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom'; // Import useNavigate

// const LoginSuperAdmin = () => {
//     const [modal, setModal] = useState(false);
//     const [modalMessage, setModalMessage] = useState('');
//     const navigate = useNavigate(); // Inisialisasi useNavigate

//     const toggleModal = () => setModal(!modal);

//     const handleLogin = async (values) => {
//         try {
//             const response = await axios.post('https://rlagency.id/api/login.php', values);
            
//             // Log respons untuk debug
//             console.log("Respons API:", response.data);
            
//             // Cek apakah login berhasil
//             if (response.data && response.data.message === "Login berhasil") {
//                 const user = response.data.user; // Ambil data user dari respons

//                 setModalMessage('Login berhasil!');
//                 toggleModal();
                
//                 // Simpan informasi pengguna dan waktu kedaluwarsa (3 jam ke depan) ke local storage
//                 localStorage.setItem('token', user.username); // Simpan username atau token sesuai kebutuhan
//                 localStorage.setItem('user', JSON.stringify(user)); // Simpan informasi pengguna
//                 localStorage.setItem('expiry', Date.now() + 3 * 60 * 60 * 1000); // 3 jam dalam milisecond
                
//                 // Redirect ke dashboard setelah beberapa detik
//                 setTimeout(() => {
//                     navigate('/dashboard'); // Ganti history.push dengan navigate
//                 }, 1000);
//             } else {
//                 console.error("Login gagal:", response.data); // Tambahkan log di sini
//                 setModalMessage('Login gagal. Silakan coba lagi.');
//                 toggleModal();
//             }
//         } catch (error) {
//             console.error("Kesalahan saat login:", error); // Tambahkan log di sini
//             setModalMessage('Terjadi kesalahan. Silakan coba lagi.');
//             toggleModal();
//         }
//     };

//     return (
//         <div>
//             <h1>Login Super Admin</h1>
//             <Formik
//                 initialValues={{ username: '', password: '' }}
//                 onSubmit={handleLogin}
//             >
//                 {({ isSubmitting }) => (
//                     <Form>
//                         <FormGroup>
//                             <Label for="username">Username</Label>
//                             <Field name="username" as={Input} type="text" id="username" placeholder="Masukkan Username" />
//                             <ErrorMessage name="username" component="div" className="text-danger" />
//                         </FormGroup>

//                         <FormGroup>
//                             <Label for="password">Password</Label>
//                             <Field name="password" as={Input} type="password" id="password" placeholder="Masukkan Password" />
//                             <ErrorMessage name="password" component="div" className="text-danger" />
//                         </FormGroup>

//                         <Button type="submit" color="primary" disabled={isSubmitting}>
//                             Login
//                         </Button>
//                     </Form>
//                 )}
//             </Formik>

//             {/* Modal for messages */}
//             <Modal isOpen={modal} toggle={toggleModal}>
//                 <ModalHeader toggle={toggleModal}>Informasi</ModalHeader>
//                 <ModalBody>
//                     {modalMessage}
//                 </ModalBody>
//                 <ModalFooter>
//                     <Button color="secondary" onClick={toggleModal}>Tutup</Button>
//                 </ModalFooter>
//             </Modal>
//         </div>
//     );
// };

// export default LoginSuperAdmin;


import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './LoginSuperAdmin.css'; // Import CSS

const LoginSuperAdmin = () => {
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const navigate = useNavigate(); // Inisialisasi useNavigate

    const toggleModal = () => setModal(!modal);

    const handleLogin = async (values) => {
        try {
            const response = await axios.post('https://rlagency.id/api/login.php', values);
            
            // Log respons untuk debug
            console.log("Respons API:", response.data);
            
            // Cek apakah login berhasil
            if (response.data && response.data.message === "Login berhasil") {
                const user = response.data.user; // Ambil data user dari respons
                const loginTime = new Date().getTime(); // Waktu sekarang dalam milidetik

                setModalMessage('Login berhasil!');
                toggleModal();
                
                // Simpan informasi pengguna dan waktu kedaluwarsa (3 jam ke depan) ke local storage
                localStorage.setItem('token', user.username); // Simpan username atau token sesuai kebutuhan
                localStorage.setItem('user', JSON.stringify(user)); // Simpan informasi pengguna
                localStorage.setItem('expiry', Date.now() + 1 * 60 * 60 * 1000); // 1 jam dalam milisecond
                localStorage.setItem('loginTime', loginTime); // Simpan waktu login
                
                // Redirect ke dashboard setelah beberapa detik
                setTimeout(() => {
                    navigate('/dash'); // Ganti history.push dengan navigate
                }, 1000);
            } else {
                console.error("Login gagal:", response.data); // Tambahkan log di sini
                setModalMessage('Login gagal. Silakan coba lagi.');
                toggleModal();
            }
        } catch (error) {
            console.error("Kesalahan saat login:", error); // Tambahkan log di sini
            setModalMessage('Terjadi kesalahan. Silakan coba lagi.');
            toggleModal();
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <h1>Login Super Admin</h1>
                <Formik
                    initialValues={{ username: '', password: '' }}
                    onSubmit={handleLogin}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <FormGroup>
                                <Label for="username">Username</Label>
                                <Field name="username" as={Input} type="text" id="username" placeholder="Masukkan Username" />
                                <ErrorMessage name="username" component="div" className="text-danger" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="password">Password</Label>
                                <Field name="password" as={Input} type="password" id="password" placeholder="Masukkan Password" />
                                <ErrorMessage name="password" component="div" className="text-danger" />
                            </FormGroup>

                            <Button type="submit" color="primary" disabled={isSubmitting}>
                                Login
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>

            {/* Modal for messages */}
            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Informasi</ModalHeader>
                <ModalBody>
                    {modalMessage}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleModal}>Tutup</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default LoginSuperAdmin;
