import React from 'react';
import { Container } from 'reactstrap';


const DashboardDefault = () => {

  return (
    <Container fluid style={{padding:'0', margin:'0'}}>
        <Container fluid style={{padding:'30px', backgroundColor:'white', borderColor:'#DBDFE6', borderStyle:'solid', borderWidth:'1px', borderRadius:'10px', marginBottom:'20px'}}>
                    <h4>Hallo admin,</h4>
                    <hr/>
                    <p>Sekedar informasi saja, produk ini dikembangkan oleh Rla Technology - Rla Agency.</p>
                    
        </Container>
    </Container>
  );
};

export default DashboardDefault;
