import React from 'react';
import { Container } from 'reactstrap';
import './HeaderSection.css'; // File CSS untuk styling

const HeaderSection = () => {
  return (
    <header className="header-section">
      <Container className="text-center header-content">
        <h1 className="header-title">Formulir Pendaftaran Foto Wisuda</h1>
        <p className="header-subtitle">Pengambilan Foto Wisuda - Penyerahan Ijazah dan Pemindahan Toga Universitas Putra Indonesia YPTK Wisuda Periode 1 T.A 2024/2025

</p>
      </Container>
    </header>
  );
};

export default HeaderSection;
