import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavItem, NavLink, Button, Table, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { RiWhatsappFill } from 'react-icons/ri'; // WhatsApp icon from React Icons
import { FaEnvelope, FaCheck } from 'react-icons/fa'; // Email and check icons from React Icons

const DashboardAdmin = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]); // State for filtered data
    const [filters, setFilters] = useState({
        nim: '',
        name: '',
        email: '',
        fakultas: '',
        jurusan: ''
    });

    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility


    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('expiry');
        navigate('/login');
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        const expiry = localStorage.getItem('expiry');
    
        // Redirect to login page if token does not exist or if token is expired
        if (!token || new Date().getTime() > expiry) {
            setIsModalOpen(true);
        }
    }, [navigate]);

    const handleModalClose = () => {
        setIsModalOpen(false);
        navigate('/login'); // Redirect to login after modal closes
    };

    // Fetch users data from API
    useEffect(() => {
        
        const fetchData = async () => {
            try {
                const response = await axios.get('https://rlagency.id/api/get_users.php');
                setData(response.data);
                setFilteredData(response.data); // Set initial data to filteredData
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    // Function to update status via API
    const updateStatus = async (nim, field, currentValue) => {
        const newValue = currentValue === '0' ? '1' : '0'; // Toggle value between 0 and 1
        try {
            const response = await axios.post('https://rlagency.id/api/change_status.php',
                new URLSearchParams({
                    nim: nim,
                    field: field,
                    value: newValue
                })
            );

            if (response.data.success) {
                // Update the data locally after successful API response
                setData(prevData =>
                    prevData.map(user =>
                        user.nim === nim
                            ? {
                                  ...user,
                                  stat_user: [
                                      {
                                          ...user.stat_user[0],
                                          [field]: newValue
                                      }
                                  ]
                              }
                            : user
                    )
                );
            } else {
                console.error('Error updating status:', response.data.message);
            }
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    // Update filter state when user types in filter inputs
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };

    // Filter data when filters change
    useEffect(() => {
        const filterData = () => {
            const filtered = data.filter(user => {
                return (
                    user.nim.toLowerCase().includes(filters.nim.toLowerCase()) &&
                    user.name.toLowerCase().includes(filters.name.toLowerCase()) &&
                    user.email.toLowerCase().includes(filters.email.toLowerCase()) &&
                    user.fakultas.toLowerCase().includes(filters.fakultas.toLowerCase()) &&
                    user.jurusan.toLowerCase().includes(filters.jurusan.toLowerCase())
                );
            });
            setFilteredData(filtered);
        };
        filterData();
    }, [filters, data]);

    // Function to download CSV
    const downloadCSV = () => {
        const csvRows = [];
        const headers = ['NIM', 'Name', 'Email','No WA', 'Fakultas', 'Jurusan', 'Graduation Date', 'Date Created', 'Dikirim', 'Foto', 'Sertifikat'];
        csvRows.push(headers.join(',')); // Add header row

        filteredData.forEach(user => {
            const row = [
                user.nim,
                user.name,
                user.email,
                user.nowa,
                user.fakultas,
                user.jurusan,
                user.graduationDate,
                new Date(user.date_created).toLocaleDateString('id-ID'),
                user.stat_user[0].dikirim === '0' ? 'Belum Diverifikasi' : 'Diverifikasi', // Change value for Dikirim
                user.stat_user[0].foto === '0' ? 'Belum Diverifikasi' : 'Diverifikasi', // Change value for Foto
                user.stat_user[0].sertifikat === '0' ? 'Belum Diverifikasi' : 'Diverifikasi' // Change value for Sertifikat
            ];
            csvRows.push(row.join(',')); // Add user data row
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'data_pengguna.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            {/* Modal for access denied */}
            <Modal isOpen={isModalOpen} toggle={handleModalClose}>
                <ModalHeader toggle={handleModalClose}>Akses Ditolak</ModalHeader>
                <ModalBody>
                    Anda tidak memiliki akses halaman ini. Silakan login terlebih dahulu.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleModalClose}>OK</Button>
                </ModalFooter>
            </Modal>

            {/* Navbar */}
            <Navbar color="light" light expand="md">
                <Nav className="me-auto" navbar>
                    <NavItem>
                        <NavLink href="#">Dashboard</NavLink>
                    </NavItem>
                </Nav>
                <Button color="danger" onClick={handleLogout}>Logout</Button>
            </Navbar>

            {/* Konten Dashboard */}
            <div className="container mt-4">
                <h1>Selamat Datang di Dashboard Admin</h1>
                <Button color="primary" onClick={downloadCSV} className="mb-3">Download CSV</Button> {/* Button to download CSV */}
                {/* Tabel Data Pengguna */}
                <Table striped>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center' }}>No</th>
                            <th>
                                NIM
                                <Input 
                                    type="text" 
                                    name="nim" 
                                    value={filters.nim} 
                                    onChange={handleFilterChange} 
                                    placeholder="Filter NIM" 
                                    size="sm" 
                                />
                            </th>
                            <th>
                                Name
                                <Input 
                                    type="text" 
                                    name="name" 
                                    value={filters.name} 
                                    onChange={handleFilterChange} 
                                    placeholder="Filter Name" 
                                    size="sm" 
                                />
                            </th>
                            <th>Email</th>
                            <th>Aksi</th>
                            <th>
                                Fakultas
                                <Input 
                                    type="text" 
                                    name="fakultas" 
                                    value={filters.fakultas} 
                                    onChange={handleFilterChange} 
                                    placeholder="Filter Fakultas" 
                                    size="sm" 
                                />
                            </th>
                            <th>
                                Jurusan
                                <Input 
                                    type="text" 
                                    name="jurusan" 
                                    value={filters.jurusan} 
                                    onChange={handleFilterChange} 
                                    placeholder="Filter Jurusan" 
                                    size="sm" 
                                />
                            </th>
                            <th>Graduation Date</th>
                            <th>Date Created</th>
                            <th>Dikirim</th>
                            <th>Foto</th>
                            <th>Sertifikat</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((user, index) => {
                            return (
                                <tr key={user.nim}>
                                    <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                    <td>{user.nim}</td>
                                    <td>{user.name}</td>
                                    <td>
                                        <Button color="primary" onClick={() => window.location.href = `mailto:${user.email}`}>
                                            <FaEnvelope />
                                        </Button>
                                    </td>
                                    <td>
                                        <Button color="success" onClick={() => window.open(`https://wa.me/${user.nowa}?text=Assalamualaikum, berikut lampiran foto anda:`, '_blank')}>
                                            <RiWhatsappFill /> {/* WhatsApp icon */}
                                        </Button>
                                    </td>
                                    <td>{user.fakultas}</td>
                                    <td>{user.jurusan}</td>
                                    <td>{user.graduationDate}</td>
                                    <td>{new Date(user.date_created).toLocaleDateString('id-ID')}</td>
                                    <td>
                                        {user.stat_user[0].dikirim === '0' ? (
                                            <Button color="warning" onClick={() => updateStatus(user.nim, 'dikirim', user.stat_user[0].dikirim)}>Done</Button>
                                        ) : (
                                            <FaCheck color="green" />
                                        )}
                                    </td>
                                    <td>
                                        {user.stat_user[0].foto === '0' ? (
                                            <Button color="warning" onClick={() => updateStatus(user.nim, 'foto', user.stat_user[0].foto)}>Done</Button>
                                        ) : (
                                            <FaCheck color="green" />
                                        )}
                                    </td>
                                    <td>
                                        {user.stat_user[0].sertifikat === '0' ? (
                                            <Button color="warning" onClick={() => updateStatus(user.nim, 'sertifikat', user.stat_user[0].sertifikat)}>Done</Button>
                                        ) : (
                                            <FaCheck color="green" />
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default DashboardAdmin;
