import React, { useEffect, useState } from 'react';
import { Button, Table, Input, Modal, ModalHeader, ModalBody, ModalFooter, Container } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Menu2 from './Menu2';

const Menu3 = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filters, setFilters] = useState({
        nim: '',
        name: '',
        email: '',
        fakultas: '',
        jurusan: ''
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(false); // State untuk refresh Menu2

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('expiry');
        navigate('/login');
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        const expiry = localStorage.getItem('expiry');

        if (!token || new Date().getTime() > expiry) {
            setIsModalOpen(true);
        }
    }, [navigate]);

    const handleModalClose = () => {
        setIsModalOpen(false);
        navigate('/login');
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://rlagency.id/api/get_users.php');
                setData(response.data);
                setFilteredData(response.data);
            } catch (error) {
                setError('Error fetching data');
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [refresh]); // Menambahkan refresh sebagai dependency

    const updateStatus = async (nim, field, currentValue) => {
        const newValue = currentValue === '0' ? '1' : '0';
        try {
            const response = await axios.post('https://rlagency.id/api/change_status.php', new URLSearchParams({ nim, field, value: newValue }));

            if (response.data.success) {
                setData(prevData =>
                    prevData.map(user =>
                        user.nim === nim
                            ? { ...user, stat_user: [{ ...user.stat_user[0], [field]: newValue }] }
                            : user
                    )
                );
                setRefresh(prev => !prev); // Refresh Menu2 setelah status diperbarui
            } else {
                setError('Error updating status');
                console.error('Error updating status:', response.data.message);
            }
        } catch (error) {
            setError('Error updating status');
            console.error('Error updating status:', error);
        }
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };

    useEffect(() => {
        const filterData = () => {
            const filtered = data.filter(user => {
                return (
                    user.nim.toLowerCase().includes(filters.nim.toLowerCase()) &&
                    user.name.toLowerCase().includes(filters.name.toLowerCase()) &&
                    user.email.toLowerCase().includes(filters.email.toLowerCase()) &&
                    user.fakultas.toLowerCase().includes(filters.fakultas.toLowerCase()) &&
                    user.jurusan.toLowerCase().includes(filters.jurusan.toLowerCase())
                );
            });
            setFilteredData(filtered);
        };
        filterData();
    }, [filters, data]);

    const downloadCSV = () => {
        const csvRows = [];
        const headers = ['NIM', 'Name', 'Email', 'No WA', 'Fakultas', 'Jurusan', 'Graduation Date', 'Date Created', 'Dikirim', 'Foto', 'Sertifikat'];
        csvRows.push(headers.join(','));

        filteredData.forEach(user => {
            const row = [
                user.nim,
                user.name,
                user.email,
                user.nowa,
                user.fakultas,
                user.jurusan,
                user.graduationDate,
                new Date(user.date_created).toLocaleDateString('id-ID'),
                user.stat_user[0].dikirim === '0' ? 'Belum Diverifikasi' : 'Diverifikasi',
                user.stat_user[0].foto === '0' ? 'Belum Diverifikasi' : 'Diverifikasi',
                user.stat_user[0].sertifikat === '0' ? 'Belum Diverifikasi' : 'Diverifikasi'
            ];
            csvRows.push(row.join(','));
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'data_pengguna.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div style={{ padding: '0' }}>
            {/* Modal for access denied */}
            <Modal isOpen={isModalOpen} toggle={handleModalClose}>
                <ModalHeader toggle={handleModalClose}>Akses Ditolak</ModalHeader>
                <ModalBody>
                    Anda tidak memiliki akses halaman ini. Silakan login terlebih dahulu.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleModalClose}>OK</Button>
                </ModalFooter>
            </Modal>

            {/* Konten Dashboard */}
            <Container fluid style={{ padding: '30px', backgroundColor: 'white', borderColor: '#DBDFE6', borderStyle: 'solid', borderWidth: '1px', borderRadius: '10px', marginBottom: '20px' }}>
                <h4>Pendaftaran Foto Wisuda Upi YPTK</h4>
                <hr />
                <Button color="primary" onClick={downloadCSV} className="mb-3">Download CSV</Button>
            </Container>

            <Container fluid style={{ padding: '0', marginBottom: '20px' }}>
                <Menu2 key={refresh} /> {/* Menambahkan key untuk merender ulang Menu2 */}
            </Container>

            {/* Tabel Data Pengguna */}
            <div className="table-container" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                <Table style={{ borderStyle: 'solid', borderWidth: '1px', borderColor: '#DBDFE6', borderRadius: '10px' }}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center' }}>No</th>
                            <th>
                                NIM
                                <Input type="text" name="nim" value={filters.nim} onChange={handleFilterChange} placeholder="Filter NIM" size="sm" />
                            </th>
                            <th>
                                Name
                                <Input type="text" name="name" value={filters.name} onChange={handleFilterChange} placeholder="Filter Name" size="sm" />
                            </th>
                            <th>Email</th>
                            <th>WA</th>
                            <th>
                                Fakultas
                                <Input type="text" name="fakultas" value={filters.fakultas} onChange={handleFilterChange} placeholder="Filter Fakultas" size="sm" />
                            </th>
                            <th>
                                Jurusan
                                <Input type="text" name="jurusan" value={filters.jurusan} onChange={handleFilterChange} placeholder="Filter Jurusan" size="sm" />
                            </th>
                            <th>Graduation Date</th>
                            <th>Date Created</th>
                            <th style={{ textAlign: 'center' }}>Dikirim</th>
                            <th style={{ textAlign: 'center' }}>Foto</th>
                            <th style={{ textAlign: 'center' }}>Sertifikat</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="12" style={{ textAlign: 'center' }}>Loading...</td>
                            </tr>
                        ) : error ? (
                            <tr>
                                <td colSpan="12" style={{ textAlign: 'center' }}>{error}</td>
                            </tr>
                        ) : (
                            filteredData.map((user, index) => ( // Menampilkan semua row
                                <tr key={user.nim}>
                                    <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                    <td>{user.nim}</td>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.nowa}</td>
                                    <td>{user.fakultas}</td>
                                    <td>{user.jurusan}</td>
                                    <td>{user.graduationDate}</td>
                                    <td>{new Date(user.date_created).toLocaleDateString('id-ID')}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        <Button onClick={() => updateStatus(user.nim, 'dikirim', user.stat_user[0].dikirim)}>
                                            {user.stat_user[0].dikirim === '0' ? 'Kirim' : 'Dikirim'}
                                        </Button>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <Button onClick={() => updateStatus(user.nim, 'foto', user.stat_user[0].foto)}>
                                            {user.stat_user[0].foto === '0' ? 'Upload' : 'Uploaded'}
                                        </Button>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <Button onClick={() => updateStatus(user.nim, 'sertifikat', user.stat_user[0].sertifikat)}>
                                            {user.stat_user[0].sertifikat === '0' ? 'Create' : 'Created'}
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </Table>
            </div>

            {/* Menampilkan jumlah data yang tampil */}
            <div style={{ marginTop: '10px' }}>
                Jumlah data yang tampil: {filteredData.length}
            </div>
        </div>
    );
};

export default Menu3;
