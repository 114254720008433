import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Container } from 'reactstrap';

import {
  CSidebarHeader,
  CNavItem,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CNavTitle,
  CContainer,
  CRow,
  CCol,
} from '@coreui/react';
import { CIcon } from '@coreui/icons-react';
import { cilBadge } from '@coreui/icons';

import Menu1 from './Menu1';
import Menu2 from './Menu2';
import Menu3 from './Menu3';
import DashboardDefault from './dashboardDefault';
import CicilanMahasiswa from '../cicilan_mhs/cicilanmahasiswa';

const Dashboard1 = () => {
  const [activeMenu, setActiveMenu] = useState('');

  const renderContent = () => {
    switch (activeMenu) {
      case 'menu1':
        return <Menu1 />;
      case 'menu2':
        return <Menu2 />;
      case 'menu3':
        return <Menu3 />;
      case 'cicilan':
          return <CicilanMahasiswa />;
      default:
        return <DashboardDefault />;
    }
  };

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('expiry');
    localStorage.removeItem('loginTime');
    navigate('/login');
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const expiry = localStorage.getItem('expiry');

    if (!token || new Date().getTime() > expiry) {
      setIsModalOpen(true);
    }
  }, [navigate]);

  const handleModalClose = () => {
    setIsModalOpen(false);
    navigate('/login');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://rlagency.id/api/get_users.php');
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const isActiveMenu = (menu) => activeMenu === menu;

  return (
    <div style={{ display: 'flex', minHeight: '100vh' }}>
      {/* Sidebar */}
      <CSidebar
        className="border-end cs-sidebar"
        colorScheme="dark"
        style={{
          height: '100vh',
          flexBasis: '250px', // Basis width for desktop
          flexShrink: 0,
          flexGrow: 0,
          overflowY: 'auto',
        }}
      >
        <CSidebarHeader className="border-bottom">
          <CSidebarBrand onClick={() => setActiveMenu('DashboardDefault')}>
            <img 
              src="./rla_logo_long.png"
              alt="Logo"
              style={{ width: '100%', height: 'auto' }}
            />
          </CSidebarBrand>
        </CSidebarHeader>
        <CSidebarNav>
          <CNavTitle>Daftar Menu</CNavTitle>
          <CNavItem
            href="#daftar"
            onClick={() => setActiveMenu('menu1')}
            style={{
              backgroundColor: isActiveMenu('menu1') ? '#F9B115' : 'transparent',
              color: isActiveMenu('menu1') ? 'black' : 'white',
            }}
          >
            <CIcon
              customClassName="nav-icon"
              icon={cilBadge}
              style={{ color: isActiveMenu('menu1') ? 'black' : 'white' }}
            />
            Foto Wisuda
          </CNavItem>

          <CNavItem
            href="#cicil"
            onClick={() => setActiveMenu('cicilan')}
            style={{
              backgroundColor: isActiveMenu('cicilan') ? '#F9B115' : 'transparent',
              color: isActiveMenu('cicilan') ? 'black' : 'white',
            }}
          >
            <CIcon
              customClassName="nav-icon"
              icon={cilBadge}
              style={{ color: isActiveMenu('cicilan') ? 'black' : 'white' }}
            />
            Cicilan Mahasiswa
          </CNavItem>

        </CSidebarNav>
        <Button color="danger" onClick={handleLogout} style={{margin:'10px'}}>Logout</Button>
      </CSidebar>

      

      {/* Main Content */}
      <div
        style={{
          flexGrow: 1,
          backgroundColor: '#F3F4F7',
          paddingTop: '20px',
          overflow: 'auto', // Adds scroll if content exceeds height
        }}
      >
        <Container fluid>{renderContent()}</Container>
      </div>
    </div>
  );
};

export default Dashboard1;
